<template>
  <b-card class="h-100" style="border-bottom: unset!important;">
    <b-row class="justify-content-between">
      <h1 id="FAQTitle" class="ml-2">FAQ</h1>
      <input placeholder="Search..." class="rounded d-none">
    </b-row>
    <b-row class="justify-content-center mb-5">
      <h2 class="text-center">LETS ANSWER SOME QUESTIONS</h2>
    </b-row>
    <b-row id="wrapper" class="justify-content-center text-left">
      <app-collapse id="appcollapse" class="w-75">
        <app-collapse-item title="General Questions" class="mb-1 border">
          <app-collapse class="w-100">
            <app-collapse-item title="Does Parrotize record and store the event media without my consent?" class="mb-1 border">
              No, there is no recording that takes place for your event without you knowing. All recording procedures happen only at your request, which requires payment when you are creating the event.
            </app-collapse-item>
            <app-collapse-item title="Will there be a technical support before/during my event?" class="mb-1 border">
              Before the participants connect to the system, our technicians make sure that all the audio/video streams are loaded and work flawlessly. <br/>
              Should any technical issues appear, our tech specialists do their best to resolve the problems quickly and accurately.
            </app-collapse-item>
            <app-collapse-item title="What servers are you using, and where are they located?" class="mb-1 border">
              We use DigitalOcean Infrastructure for <strong>Parrotize</strong>, and our servers are located in European Union.
            </app-collapse-item>
            <app-collapse-item title="How do you ensure the privacy of the meeting?" class="mb-1 border">
              We built moderation tools to ensure the privacy of your event is exactly how you want to be. The event owner can see and kick any participant currently invited to the event.<br>
              In addition, you can invite participants with access codes and make your event private. There is also moderation tools to kick or ban any speaker or interpreter currently speaking.
            </app-collapse-item>
            <app-collapse-item title="How does Parrotize protect the system from cyber-attacks??" class="mb-1 border">
              To protect the system from cyber-attacks, we use a list of different technological measures such as 24/7 network monitoring, storage of logs entries, audit checks, and vulnerability scans.
            </app-collapse-item>
          </app-collapse>
        </app-collapse-item>
        <app-collapse-item title="For Technicians" class="mb-1 border">
          <app-collapse class="w-100">
            <app-collapse-item title="What equipment do I need for meetings?" class="mb-1 border">
              For on-site meetings, there are different configurations, but this is the minimum equipment that you need to use <strong>Parrotize</strong>:
              <br>
              <br>
              <br>
              1. A computer with a browser installed(Chrome, Firefox etc), connected to ethernet with a 10mbps dedicated bandwidth.
              <br>
              <br>
              2. USB audio interface to be able to capture the audio from the event
              <br>
              <br>
              3. Digital video interface to be able to capture the video from the event
              <br>
              <br>
              If Interpreters are remote, they will use their own computer, Internet connection, and headsets.
              <br>
              <br>
              <br>
              If they are on-site, you will need to either use traditional hardware or have the following for each interpreter:
              <br>
              <br>
              1. A computer with a browser installed(Chrome, Firefox etc), connected to ethernet with a 10mbps dedicated bandwidth.
              <br>
              <br>
              2. A headset or mic+headphones connected to the computer (for the best quality, we recommend using USB connections);
              <br>
              <br>
              <br>
              Make sure to always connect your computer to the power source and turn off power-saving options.
              <br>
              <br>
              All of the above apply to remote meetings as well.
              <br>
            </app-collapse-item>
            <app-collapse-item title="Can Parrotize work offline without the Internet on a local Wi-Fi?" class="mb-1 border">
              No. <strong>Parrotize</strong> does not work on the local network and requires Internet access.
            </app-collapse-item>
          </app-collapse>
        </app-collapse-item>
        <app-collapse-item title="For Event Organizers" class="mb-1 border">
          <app-collapse class="w-100">
            <app-collapse-item title="How can I use Parrotize for a remote meeting?" class="mb-1 border">
              You can use Parrotize to
              <br>
              <br>
              - Interpret your event in an unlimited number of languages
              <br>
              - Connect all participants in one platform
              <br>
              - Moderate an event
              <br>
              - Discuss ideas in group chats
              <br>
              <br>
              And more!
            </app-collapse-item>
            <app-collapse-item title="Can we test how Parrotize works?" class="mb-1 border">
              Yes, you can schedule a demo and try the system yourself! All users are eligible for a 7 day free trial, which you can use the system in full capacity in your trial event.
            </app-collapse-item>
            <app-collapse-item title="Does Parrotize provide automatic interpretation?" class="mb-1 border">
              <strong>Parrotize</strong> specializes in human interpretation, thus does not provide automatic interpretation. We provide easy to use and effortless tools for interpretation to your interpreters.
            </app-collapse-item>
            <app-collapse-item title="How is Parrotize different from an automatic interpretation?" class="mb-1 border">
              Automatic interpretation uses artificial intelligence in place of a human interpreter.
              <br>
              <br>
              Instead, <strong>Parrotize</strong> provides software that transmits the original audio of the speaker to the human interpreter, allowing for the precise simultaneous interpretation with no delay.
            </app-collapse-item>
            <app-collapse-item title="How long in advance should I set my event?" class="mb-1 border"><!--bu cümle çok kötü oldu ya :/-->
              You can create your event how long in advance you want! Although we recommend to create your event at least a day before to have enough time for setting everything up.
            </app-collapse-item>
            <app-collapse-item title="What is Parrotize's payment method?" class="mb-1 border">
              We currently work with one time pricing. After you register, you can go to the <a href="https://app.parrotize.com/order">order page</a> and see how much you have to pay for an event.
            </app-collapse-item>
          </app-collapse>
        </app-collapse-item>
        <app-collapse-item title="For Speakers" class="mb-1 border">
          <app-collapse class="w-100">
            <app-collapse-item title="What audio/video equipment should I have?" class="mb-1 border">
              The audio equipment directly corresponds with the quality of your stream.
              <br>
              <br>
              These are our recommendations:
              <br>
              <strong>The HyperX SoloCast</strong> - with USB Connector
              <br>
              <strong>Razer Seiren Mini</strong> - with USB Connector
              <br>
              <strong>EPOS B20</strong> - with USB Connector
              <br>
              As for video; any camera should work, however we recommend a camera that streams in 720p for best experience.
            </app-collapse-item>
            <app-collapse-item title="What network capabilities (requirements) do I need to attend an event?" class="mb-1 border">
              Speakers are required to have a stable 10-15 Mbps upload/download speed. An ethernet connection is advised for avoiding network problems.
            </app-collapse-item>
            <app-collapse-item title="What devices do I have to have for speaking?" class="mb-1 border">
              1. A computer with a browser installed(Chrome, Firefox etc), connected to internet with a 10mbps dedicated bandwidth.
              <br>
              <br>
              2. A headset or mic+headphones connected to the computer (for the best quality, we recommend using USB connections);
              <br>
              <br>
              3. A camera connected to the computer
            </app-collapse-item>
          </app-collapse>
        </app-collapse-item>
        <app-collapse-item title="For Interpreters" class="mb-1 border">
          <app-collapse class="w-100">
            <app-collapse-item title="What audio equipment should I have?" class="mb-1 border">
              The audio equipment directly corresponds with the quality of your stream.
              <br>
              <br>
              These are our recommendations:
              <br>
              <strong>The HyperX SoloCast</strong> - with USB Connector
              <br>
              <strong>Razer Seiren Mini</strong> - with USB Connector
              <br>
              <strong>EPOS B20</strong> - with USB Connector
            </app-collapse-item>
            <app-collapse-item title="What network capabilities (requirements) do I need to work as an interpreter?" class="mb-1 border">
              Interpreters are required to have a stable 10-15 Mbps upload/download speed. An ethernet connection is advised for avoiding network problems.
            </app-collapse-item>
            <app-collapse-item title="What devices do I have to have for Interpreting?" class="mb-1 border">
              1. A computer with a browser installed(Chrome, Firefox etc), connected to internet with a 10mbps dedicated bandwidth.
              <br>
              <br>
              2. A headset or mic+headphones connected to the computer (for the best quality, we recommend using USB connections);
            </app-collapse-item>
          </app-collapse>
        </app-collapse-item>
        <app-collapse-item title="For Participants" class="mb-1 border">
          <app-collapse class="w-100">
            <app-collapse-item title="Which devices can I use to attend an event?" class="mb-1 border">
              You can use smartphones, stationary computers, laptops, and tablets. Android, iOS, MacOS, and Windows systems are all supported.
            </app-collapse-item>
            <app-collapse-item title="Do Parrotize requires payment for participating in an event?" class="mb-1 border">
              No. Currently we only request payment from event organizers, So no payment for participants is required.
            </app-collapse-item>
            <app-collapse-item title="What network capabilities do I need to join as a participant?" class="mb-1 border">
              Participants joining the event are required to have a minimum of 5 Mbps download speed. For better audio and video quality, we recommend 10-15 Mbps download.
            </app-collapse-item>
          </app-collapse>
        </app-collapse-item>
      </app-collapse>
    </b-row>
  </b-card>
</template>

<script>

import {
  VBToggle,
  BCollapse,
  BRow,
  BCard,
  BCardHeader,
  BCardBody,
  BCardText,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormCheckbox,
} from "bootstrap-vue";
import CollapsePlugin from "bootstrap-vue";

import AppCollapse from '../assets/app-collapse/AppCollapse.vue'
import AppCollapseItem from '../assets/app-collapse/AppCollapseItem.vue'

export default {
  directives: {
    "b-toggle": VBToggle,
  },
  components: {

    AppCollapse,
    AppCollapseItem,
    BCollapse,
    BFormCheckbox,
    BRow,
    BCol,
    BFormInput,
    BCardHeader,
    BCardBody,
    BCardText,
    BButton,
    BTable,
    BCard,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    CollapsePlugin,
  },
  data() {
    return {

    };
  },
};
</script>
<style scoped>
@media screen and (max-width: 640px) {
  #appcollapse{
    width: 100%!important;
  }
  #FAQTitle{
    display:none!important;
  }
}
</style>
